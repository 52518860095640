::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(70, 70, 70, 0.07);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(70, 70, 70, 0.2);
}

::-webkit-scrollbar-thumb {
  background: rgba(70, 70, 70, 0.5);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar-thumb:active {
  background: rgba(70, 70, 70, 0.61);
  -webkit-border-radius: 100px;
}

body,
html {
  --terminal-bg: rgb(6, 13, 28);
  --page-margin-bottom: 96px;

  overflow: hidden;
  height: 100vh;
}
